"use client";
import React, { useEffect, useRef } from "react";
import "~/styles/AnimatedBoxes.css";
const SHOW_NUMBERS = false;

/*
A graphic of animated boxes floating around  in a graph/grid
*/
const FloatingNodes: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateLines = () => {
      const boxes = Array.from(
        container.getElementsByClassName("box"),
      ) as HTMLElement[];
      const existingLines = container.getElementsByClassName("line");

      // Remove existing lines
      while (existingLines.length > 0) {
        if (existingLines[0]) existingLines[0].remove();
      }

      // Create lines between boxes
      boxes.forEach((box1, i) => {
        boxes.slice(i + 1).forEach((box2, lineIndex) => {
          const containerRect = container.getBoundingClientRect();
          const rect1 = box1.getBoundingClientRect();
          const rect2 = box2.getBoundingClientRect();

          // Calculate positions relative to container
          const x1 = rect1.left - containerRect.left + rect1.width / 2;
          const y1 = rect1.top - containerRect.top + rect1.height / 2;
          const x2 = rect2.left - containerRect.left + rect2.width / 2;
          const y2 = rect2.top - containerRect.top + rect2.height / 2;

          const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
          const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;

          const line = document.createElement("div");
          line.className = "line";
          line.style.width = `${length}px`;
          line.style.left = `${x1}px`;
          line.style.top = `${y1}px`;
          line.style.transform = `rotate(${angle}deg)`;

          // messing with hiding/showing lines for asthetic purposes

          if (
            !(i === 0 && (lineIndex === 4 || lineIndex === 5)) &&
            !(i === 4 && lineIndex <= 1)
          )
            container.appendChild(line);
        });
      });
    };

    // Update lines on resize
    const resizeObserver = new ResizeObserver(() => {
      updateLines();
    });
    resizeObserver.observe(container);

    // Update lines periodically for animations
    const interval = setInterval(updateLines, 50);

    return () => {
      clearInterval(interval);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="container relative h-full w-full" ref={containerRef}>
      {[...Array(11)].map((_, i) => (
        <div
          key={i}
          className="box flex items-center justify-center bg-gray-400 text-xs text-white"
        >
          {SHOW_NUMBERS ? i + 1 : ""}
        </div>
      ))}
    </div>
  );
};

export default FloatingNodes;
